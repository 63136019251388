* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.projects-nav_container {
  height: 100vh;
  width: 100%;
  font-family: inherit;
  position: absolute;
  transition: all .5s ease-in;
}

.projects-nav_header {
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

.projects-nav_header-tag {
  margin: 3rem;
  color: #332D33;
  font-family: inherit;
  font-weight: 300;
  text-transform: uppercase;
  opacity: 0;
  transition: all .5s ease-in;
}

.projects-nav_tag {
  text-transform: uppercase;
  font-family: inherit;
  font-size: 6rem;
  margin-bottom: .5rem;
  color: #292438;
  pointer-events: auto;
  opacity: 0;
  transition: opacity .3s ease-in;
  font-weight: 900;
  position: relative;
  z-index: 3;

  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #292438;

  &:hover {
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-color: #fff;
  }
}

.projects-nav {
  height: 65vh;
  width: 80%;
  margin: 0 auto;
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-size: cover;
  background-position: center;
  &:hover > .projects-nav_tag:not(:hover) {
    -webkit-text-stroke-color: #fff;
  }
}

.lunaire {
  transform: translateY(-.5rem);
  
  &:hover ~ .home-background-image {
    opacity: 1;
    background-image: url('../assets/lunaire-header.png');
  }
}

.zogra {
  &:hover ~ .home-background-image {
    opacity: 1;
    background-image: url('../assets/zogra-round-header.jpg');
  }
}

.hawaii:hover ~ .home-background-image {
  opacity: 1;
  background-image: url('../assets/hawaii-header.jpg');
}

.home-background-image {
  width: 80%;
  top: 10rem;
  height: 65vh;
  position: absolute;
  background-size: cover;
  background-position: center;
  z-index: 1;
  opacity: 0;
  transition: all .2s;
}

.visible {
  opacity: 1;
}

.translate_y {
  transform: translateY(-.5rem);
}

.splash-page {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splash-page_logo {
  background-image: linear-gradient(90deg, #fff 0%, #fff 2%, #292438 00%);
  background-size: 200%;
  width: 30rem;
  transition: all .5s;
}

.slider {
  background-size: 5000%;
}

.invisible {
  opacity: 0;
}

.display_none {
  display: none;
}


.header-img {
  width: 100%;
}


.project-page {
  position: absolute;
  height: 100vh;
  width: 100%;
  font-family: inherit;
  word-wrap:break-word;

  .forrest-logo {
    position: fixed;
    z-index: 99999;
    top: 2rem;
    right: 50%;
    height: 4rem;
    width: 3rem;
    font-size: -1rem;
    transition: all .3s;
    mix-blend-mode: difference;
    cursor: pointer;
    /* background: black; */
  }
}

.project-page_header {
  height: 100vh;
  width: 100%;
  transition: all .3s ease-in;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
  
  h1 {
    text-transform: uppercase;
    font-size: 3.5rem;
    transform: translateY(-.75rem);
    color: #FFFFFF;
    transition: all .5s ease-in;
    // opacity: 1;
  }
}

.lunaire-header {
  background-size: cover;
  background-image: url("../assets/lunaire-header.png");
}

.hawaii-header {
  background-image: url("../assets/hawaii-header.jpg");
}

.zogra-header {
  background-image: url('../assets/zogra-round-header.jpg');
}

.first-text-section {
  width: 85%;
  margin: 0 auto;
  transform: translateY(6rem);
  transition: all .5s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  &_header-two {
    transform: translateY(2rem);
  }

  &-left-half {
    float: left;
  }

  &-right-half {
    width: 64%;
    display: flex;
    flex-direction: column;
  }

  &-right-half-row {
    float: right;
    width: 45rem;
    margin-bottom: 6rem;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .sub-header {
      display: inline-block;
    }

    .full-paragraph {
      display: inline-block;
      width: 35rem;
      word-wrap:break-word;
    }
  }

  div {
    ul {
      list-style: none;

      li {
        font-size: .95rem;
      }
    }
  }
}


.sub-header {
  font-weight: 600;
  font-size: 1.4rem;
}

.slide-header-up {
  height: 55vh;
}

.slide-text-up {
  transform: translateY(5rem);
}

.hawaii-strategy {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  justify-content: space-around;
  margin: 0 auto;
  position: relative;
  transform: translateY(5rem);

  &-text {
    width: 56%;
    height: auto;
    margin-left: 44%;
    transform: translateY(4rem);

    h2 {
      text-transform: uppercase;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 2rem;
      width: 39rem;
    }
  }

  .img-section {
    position: absolute;
    left: 0;
    width: 30%;
    
    .img-first {
      width: 100%;
    }
    
    .wavy {
      width: 85%;
      position: absolute;
      bottom: 8%;
      left: 60%;
    }
  }
}

.zogra-first {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  justify-content: space-around;
  margin: 0 auto;
  position: relative;
  transform: translateY(5rem);

  &-text {
    width: 35rem;
    height: 56vh;
    position: absolute;
    top: 9rem;
    right: 20%;
    display: inline;

    h2 {
      text-transform: uppercase;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 2rem;
      width: 35rem;
    }
  }

  .img-section {
    position: absolute;
    left: 10%;
    width: 30%;
    
    .img-first {
      width: 26rem;
    }
    
    .wavy {
      width: 85%;
      position: absolute;
      bottom: 8%;
      left: 60%;
    }
  }
}

hr {
  width: 90%;
  margin: 0 auto;
}

.logo-description {
  margin: 0 auto;
  margin-top: 4rem;
  width: 35rem;
  text-align: left;

  h2 {
    margin-bottom: 2rem;
  }

  p {
    font-weight: light;
  }
}

.zogra-strategy {
  margin-top: 7rem;
}

.zogra-design {
 
  p {
    margin-bottom: 2rem;
  }
}

.laser-etched {
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

.round-designs {
  width: 60%;
  margin: 0 auto;
  margin-top: 5rem;

  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }

  p {
    font-size: .75rem;
    font-weight: 600;
  }

  .round-description {
    float: right;
    margin-top: 2rem;
  }
}

.typeface-1 {
  margin-top: 9rem;
  width: 80%;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

.fontlab {
  img {
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }
}

.zogra-image-container {
  margin-top: 12rem;
  width: 80%;

  img {
    margin-bottom: 4rem;
  }

  &>* {
    margin-bottom: 4rem;
  }
}

.zogra-full {
  width: 80%;
  display: block;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  margin: 0 auto;
}

.third-round-designs {
  margin: 0 auto;
  margin-top: 4rem;
  width: 85%;

  p {
    font-size: .75rem;
    transform: translateX(15%);
  }

  .logo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    width: 95%;

    img {
      width: 35rem;
      height: 25rem;
      margin-right: 0.5rem;
      margin-bottom: 1.4rem;
      box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    }
  }
}

.design-section {
  width: 100%;
}


.design-section-description {
  width: 40%;
  margin: 0 auto;
  margin-top: 4rem;
  text-align: left;

  h2 {
    margin-bottom: 2rem;
  }

}

.image-section-primary {
  width: 80%;
  margin: 0 auto;
  margin-top: 4rem;
  position: relative;

  .sepia-sail {
    width: 100%;
    display: inline-block;
    margin: 0 auto;
  }

  .circle {
    position: absolute;
    right: -10%;
    bottom: -15%;
    width: 20%;
  }
}

.color-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  width: 100%;
  position: relative;

  h3 {

    transform: rotate(-90deg);
    position: absolute;
    left: 2%;
    font-size: 3.5rem;
    top: 10%;
  }
}

.color-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 3%;
}

.color-block {
  width: 16rem;
  height: 16rem;
  margin-right: 2rem;
  margin-bottom: 3rem;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

.fonts-image {
  margin: 0 auto;
  display: block;
  width: 75%;
}

.ux-design-text {
  margin: 0 auto;
  margin-top: 4rem;
  width: 55%;

  &>* {
    margin-bottom: 2rem;
  }
}

.image-block {
  width: 70%;
  margin: 0 auto;
  margin-top: 3rem;

  img {
    width: 100%;
  }

  p {
    font-size: .75rem;
    float: right;
  }
}

.hawaii-sail-full-site {
  display: block;
  width: 90%;
  margin: 0 auto;
  margin-top: 5rem;
}

.nav-container {
  position: fixed;
  top: 3rem;
  right: 3rem;
}

footer {

  margin-top: 4rem;
  height: 6rem;
  display: flex;
  position: relative;

  // a {
  //   font-size: 3rem;
  //   align-self: center;
  //   text-decoration: none;
  //   font-weight: 600;
  // }

  .footer-right {
    background: #292438;
    width: 80%;
    height: 6rem;
    position: absolute;
    right: 0;
  }

  &>* {
    display: inline-block;
  }
}

// .corner-nav {
//   &,
//   &::after {
    
//     height: 2px;
//     width: 2rem;
//     background: black;
//     display: inline-block;
//   }

//   &::after {
//     content: "";
//     width: 1rem;
//     transform: translateX(1rem);
//   }
// }

.navigation-nav {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #292438;
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 200000000;
  transition: all .3s;

  p {
    color: #EFEFEF;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 3rem;
    right: 3rem;
  }

  ul {
    list-style: none;
    text-align: center;

    a {
      color: #EFEFEF;
      font-size: 4rem;
    }
  }
}

.navigation-button {

  height: 2.5rem;
  width: 2.5rem;
  position: fixed;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  z-index: 10000;

  &-appearance {
  &,
  &::after {
    
    height: 2.4px;
    width: 2rem;
    background: black;
    display: inline-block;
    transform: translateY(.5rem);
  }

  &::after {
    content: "";
    width: 1.5rem;
    transform: translateX(.5rem) translateY(-.3rem);
  }



  }
}

// .corner-nav {
//   &,
//   &::after {
    
//     height: 2px;
//     width: 2rem;
//     background: black;
//     display: inline-block;
//   }

//   &::after {
//     content: "";
//     width: 1rem;
//     transform: translateX(1rem);
//   }
// }

.slide-nav-out {
  left: 0;
}

.about-and-contact-page {
  position: absolute;
  height: 100vh;
  width: 100%;
  transition: all .3s ease-in;

  .text-area {
    height: 30vh;
    width: 50%;
    transform: translateY(-8rem) translateX(6rem);
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  

  .page-container {
    height: 100vh;
    width: 100%;
    position: absolute;


    .navigation-button {
      
      &-appearance {
        background: #fff;

        &::after {
          background: #fff;
        }
      }
    }
  }

  &-left {
    background: #fff;
    float: left;
    height: 100vh;
    width: 25%;
  }

  &-right {
    background: #292438;
    float: right;
    height: 100vh;
    width: 75%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .about-h1 {
      float: right;
      color: #fff;
      font-size: 9rem;
      transition: all .3s ease-in;
    }

    .about-p {
      color: #fff;
      width: 80%;
      transform: translateY(-1rem);
      transition: all .3s ease-in;
    }

    .forrest-logo {  
      position: absolute;
      top: 3rem;
      right: 68%;
      width: 3rem;
    }
  }

  .forrest-pic {
    position: absolute;
    width: 65%;
    bottom: 0;
    right: 50%;
    transition: all .3s ease-in;
  }
}

.contact-text-area {
  color: #EFEFEF;
  text-align: left;
  width: 19rem;
  position: absolute;
  left: 45%;
  top: 11rem;

  h1 {
    font-size: 9rem;
    height: 13.5rem;
  }

  p {
    font-weight: 300;
    height: 4.5rem;
    transition: all .3s ease-in;
  }

  h3 {
    font-size: 2rem;
    font-weight: 600;
    // transition: all .3s ease-in;
  }
}

.horizantal-scroll {
  margin-top: 12rem;
  position: relative;

  p {
    position: absolute;
    top: -3rem;
    right: 35%;
    font-weight: 600;
    width: 43rem;
    font-size: 0.75rem;
  }

  &-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: scroll;
    padding: 1rem;

    img {
      width: 25%;
      height: 0%;
      margin-right: 2rem;
    }
  }
}

.corners {
  img {
    width: 40%;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }
}

.narrowed-down {
  width: 70%;
  margin: 0 auto;
  margin-top: 8rem;
  text-align: right;
  position: relative;

  img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  
  &-primary {
    float: left;
    font-size: .85rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .font-type {
    margin-bottom: 4rem;
    font-size: .75rem;
    font-weight: 600;
  }
}

.lunaire-image-section {
  width: 80%;
  margin: 0 auto;
  transform: translateY(4rem);
  margin-top: 4rem;

  img {
    width: 100%;
  }

  p {
    font-weight: 600;
    font-size: .75rem;
  }
}

.lunaire-paragraph {
  width: 47rem;
  margin: 0 auto;
  margin-top: 14rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.paragraph-2 {
  margin-top: 9rem;
}

.lunaire-color-section {

  width: 100%;
  margin: 0 auto;
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform: translateX(-5%);

  &>* {
    margin-right: 2rem;
  }

  img {
    width: 16rem;
    height: 16rem;
  }

  h2 {
    transform: rotate(-90deg) translateY(45%) translateX(1rem);
    font-size: 3.5rem;
  }


  .lunaire-color {
    background: #DBDBDB;
    border: 6px solid #FFFFFF;
    height: 10rem;
    width: 10rem;
    // display: inline-block;
  }
}

.lunaire-font-section {
  width: 80%;
  margin: 0 auto;
  margin-top: 10rem;

  img {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
}

.font-color {
  height: 50vh;
  width: 62rem;
  transform: translateX(2%);
  background: #D2BB8F;
}

.lunaire-full {
  display: block;
  width: 60%;
  margin: 0 auto;
  margin-top: 17rem;
}

.water-landing {
  width: 100%;

  img {
    width: 100%;
  }
}

.third-description {
  margin-top: 4rem;
}

.type-specimen {
  width: 80%;
  margin: 0 auto;
  text-align: right;
  font-size: .85rem;
  font-weight: 600;
}

.next {
  font-size: 3rem;
  align-self: center;
  text-decoration: none;
  font-weight: 600;
  color: #292438
}


@media only screen and (min-width: 1700px) {

  .first-text-section {
    width: 66%;
  }

  .zogra-first {
    width: 80%;
  }
}

@media only screen and (max-width: 1435px) {
  
  .zogra-first {
    flex-direction: column;
    width: 70%;
    margin-top: 4rem;
    height: auto;

    &-text {
      position: static;
    }

    .img-section {
      position: static;
      width: 100%;

      .img-first {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1170px) {


  .first-text-section {
    flex-direction: column;

    &-left-half {
      display: flex;
      width: 30rem;
      justify-content: space-around;
      margin-bottom: 4rem;
      transform: translateX(-12%);
    }

    &_header-two {
      transform: translateY(0rem); 
    }
  }
}


//smaller styles

// .first-text-section-left-half {
//   display: flex;
//   width: 40%;
//   justify-content: space-around;
// }

//.first-text-section {
//  flex-direction: column
//}

// .first-text-section_header-two {
  /* transform: translateY(0rem); */
// }